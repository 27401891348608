#schedpos {
  margin-right: 10px;
}
#calbtnsave {
  margin-top: 10px;
}

#companycalendar .dx-scheduler-cell-sizes-horizontal {
  width: 170px;
}
#companycalendar .dx-scheduler-cell-sizes-vertical {
  height: 45px;
}
