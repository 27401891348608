#contact-edit {
  margin-right: 20px;
}

#contact-edit-company {
  margin-right: 20px;
}
#emplpyeegrid {
  margin-right: 20px;
  height: 155px;
  width: 100%;
}
#contact-edit-employee {
  margin-right: 20px;
  margin-left: 20px;
}
#contactpopbutton {
  margin-left: auto;
  margin-right: 0;
}
#contact-type-select {
  margin-left: 20px;
  margin-top: 20px;
}
