#emplpyeegrid {
  margin-right: 20px;
  height: 90%;
  width: 100%;
}
#employeeform {
  margin-right: 20px;
  margin-left: 40px;
}
#clients-active-transaction-Grid {
  margin-top: 10px;
  margin-right: 10px;
  height: 380px;
}
#clients-acvtive-btn-section {
  margin-top: 10px;
  margin-right: 10px;
  margin-bottom: 0px;
}
#clients-acvtive-btn-section-2 {
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 0px;
}
#clients-find-grid {
  height: 400;
}
#client-inquiry-Grid {
  margin-top: 10px;
  margin-right: 20px;
  height: 340px;
}

#client-top-grid-section {
  margin-right: 10px;
  margin-bottom: 10px;
  margin-left: 10px;
  height: 380px;
}
#client-bottom-half {
  margin-right: 10px;
  margin-left: 10px;
  margin-bottom: 10px;
}
#active-client-approval {
  margin-top: 20px;
}
#client-access-grid {
  margin-top: 0px;
}
#client-access-info {
  margin-top: 40px;
  margin-bottom: 20px;
}
#access-pop-savebutton-right {
  margin-right: 10px;
}
#access-pop-closebutton-right {
  margin-left: auto;
  margin-right: 10;
  margin-top: 0px;
  margin-bottom: 10px;
}
#client-access-onboarding {
  margin-top: 20px;
}
#client-invited-user-grid {
  height: 80px;
}
#client-connected-user-grid {
  height: 240px;
}
#client-details-info {
  margin-top: 20px;
}
#client-approval-pop-one {
  margin-top: 10px;
}
#client-approval-pop-grid {
  margin-top: 10px;
  width: 600px;
}
#client-approval-pop-two {
  margin-top: 10px;
}
#client-approval-pop-area {
  margin-left: 10px;
}
#client-search-section {
  margin: 10px;
  width: 100%;
}
#clients-section-cbx {
  margin-top: 20px;
}
#transaction-request-cbx {
  margin-left: 10px;
  margin-top: 30px;
}
#transaction-request-area-1 {
  margin-left: 10px;
  margin-top: 10px;
}
#signup-pop-region {
  margin: 10px;
}
#signup-pop-label {
  margin-bottom: 5px;
}
#signup-pop-select {
  margin-bottom: 30px;
}
#client-access-form {
  margin-left: 20px;
  margin-top: 10px;
}
#client-user-approval-pop-grid {
  margin-top: 10px;
  width: 600px;
  height: 200px;
}
#contact-section-grid {
  margin-bottom: 20px;
}
