#mailbutton {
  margin-top: 30px;
  margin-left: 0px;
}

#mail-template-data-grid-box {
  margin-top: 30px;
}

#mail-template-grid {
  height: 500;
}

#mail-template-form {
  margin-top: 30px;
}

#templatebutton-right {
  margin-right: 10px;
}

#templatebutton-copy {
  margin-left: 30px;
}
