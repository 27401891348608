signup_doc .partnerEditReqDoc-btn {
  margin-right: 10px;
  margin-bottom: 10px;
}

#req-doc-Grid {
  width: 1024px;
}

.fileuploader-container {
  border: 1px solid #d3d3d3;
}

.btn-popup {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  float: right;

  .grid-partnerEditReqDoc {
    display: grid;
    grid-template-columns: 25px repeat(3, 1fr) 11px;
    grid-template-rows: 50px 1fr 50px 1fr;
    grid-column-gap: 0px;
    grid-row-gap: 10px;
  }

  .partnerEditReqDoc-gridArea-small-side-left-1 {
    grid-area: 1 / 1 / 2 / 2;
  }
  .partnerEditReqDoc-gridArea-header {
    grid-area: 1 / 2 / 2 / 5;
  }
  .partnerEditReqDoc-gridArea-small-side-right-1 {
    grid-area: 1 / 5 / 2 / 6;
  }
  .partnerEditReqDoc-gridArea-small-side-left-2 {
    grid-area: 2 / 1 / 3 / 2;
  }
  .partnerEditReqDoc-gridArea-dataGrid-state {
    grid-area: 2 / 2 / 3 / 5;
  }
  .partnerEditReqDoc-gridArea-small-side-right-2 {
    grid-area: 2 / 5 / 3 / 6;
  }
  .partnerEditReqDoc-gridArea-small-side-left-3 {
    grid-area: 3 / 1 / 4 / 2;
  }
  .partnerEditReqDoc-gridArea-btn {
    grid-area: 3 / 2 / 4 / 4;
  }
  .partnerEditReqDoc-gridArea-span1-empty {
    grid-area: 3 / 4 / 4 / 5;
  }
  .partnerEditReqDoc-gridArea-small-side-right-3 {
    grid-area: 3 / 5 / 4 / 6;
  }
  .partnerEditReqDoc-gridArea-small-side-left-4 {
    grid-area: 4 / 1 / 5 / 2;
  }
  .partnerEditReqDoc-gridArea-dataGrid-documents {
    grid-area: 4 / 2 / 5 / 5;
  }
  .partnerEditReqDoc-gridArea-small-side-right-4 {
    grid-area: 4 / 5 / 5 / 6;
  }

  .partnerEditReqDoc-btn {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  #req-doc-Grid {
    width: 1024px;
  }

  #requiredDocFileUploader {
    .dx-button {
      height: 35px;
    }
  }

  signup_doc .partnerEditReqDoc-btn {
    width: 100px !important;
    margin-right: 5px;
  }

  .partnerEditReqDoc-gridArea-header {
    grid-area: 1 / 2 / 2 / 5;
  }
  .partnerEditReqDoc-gridArea-dataGrid-state {
    grid-area: 2 / 2 / 3 / 5;
  }
  .partnerEditReqDoc-gridArea-btn {
    grid-area: 3 / 2 / 4 / 5;
  }
  .partnerEditReqDoc-gridArea-dataGrid-documents {
    grid-area: 4 / 2 / 5 / 5;
  }
}

#btn-popup-dlg {
  margin-top: 10px;
  margin-bottom: 10px;
  margin-right: 0px;
  float: right;
}

#doc-add-dialog {
  margin: 10px;
}
