#menu-user-grid {
  margin-left: 10px;
  margin-right: 10px;
  height: 350px;
}

#menuadminButton {
  margin-top: 10px;
  margin-right: 10px;
  margin-left: 10px;
}

#user-panel {
  margin-bottom: 10px;
}