#service-assets-sections-grid {
  margin-left: 30px;
  margin-bottom: 50px;
  margin-top: 0px;
  height: 450px;
  width: 65%;
}

#portalbutton {
  margin-top: 30px;
  margin-left: 20px;
}

#portal-data-grid-box {
  margin-top: 30px;
  height: 700px;
}
