#lp-type-tab-grid {
  margin-left: 20px;
  height: 650px;
  width: 80%;
}
#lptype-tab-entries-grid {
  height: 565px;
  width: 100%;
}
#form-lptype-tab-data {
  margin-right: 30px;
}
#lp-companytab-box {
  margin-left: 20px;
  margin-top: 20px;
  width: 60%;
}
#lp-companytab-box-grid {
  margin-top: 20px;
}
