#usersGrid {
  margin-right: 20px;
  height: 520px;
}
#sysbutton {
  margin-left: 10px;
  margin-right: 10px;
}
#sysbutton-2 {
  margin-top: 10px;
}
#usereditarea {
  margin-right: 20px;
}
#userclosebutton {
  margin-left: auto;
  margin-right: 0;
  margin-top: 30px;
}
#companyGrid {
  width: 250px;
  height: 520px;
}
#companytab-box {
  margin-top: 20px;
  margin-left: 30px;
}
#companytab-button {
  margin-left: 10px;
}
#companytab-grid {
  margin-left: 30px;
  margin-bottom: 50px;
  margin-top: 0px;
  height: 450px;
  width: 40%;
}
#portalusersGrid {
  margin-bottom: 40px;
  height: 620px;
}
#puserbutton {
  margin-left: 10px;
  margin-right: 10px;
}
#puserbutton-2 {
  margin-top: 10px;
}
#puser-form {
  margin: 20px;
}
#user-roles-permissions {
  margin-left: 10px;
  margin-top: 20px;
}
#Portal-User-Role-Grid {
  height: 560px;
}
